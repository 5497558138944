<template>
    <mercur-card class="c-customer-box">
        <div class="d-flex">
            <div class="flex-grow-1 pr-3">
                Type customer email
                <mercur-input data-e2e="customerEmailInput" @keyup="searchCustomers($event)"></mercur-input>
            </div>
            <div v-if="withCreate" class="d-flex align-items-end justify-content-end"><mercur-button @click="isOpen = true" class="btn btn-primary mb-3">Create new customer</mercur-button></div>
        </div>
        <slot></slot>
        <div class="row">
            <div class="col-3 mb-2" v-for="(customer, index) in customers" :key="index">
                <mercur-card class="h-100">
                    <div>
                        <div><router-link :to="{name: 'CustomerView', params: { customerId: customer.accountId, applicationId: selectedShop.applicationId }}">{{ customer.firstName }} {{ customer.lastName }}</router-link></div>
                        <div class="c-customer-box__email">{{ customer.email }}</div>
                    </div>
                    <div class="text-right">
                        <h3 v-if="isSelectedCustomer(customer)" class="m-0 mt-3"><i class="far fa-check-circle"></i></h3>
                        <mercur-button data-e2e="customerSelect" v-else class="btn mt-2 c-customer-box__button" @click.native="selectCustomer(customer)" :disabled="isSelectedCustomer(customer)">Choose</mercur-button>
                    </div>
                </mercur-card>
            </div>
        </div>
        <template v-if="withCreate">
            <mercur-dialog :is-open.sync="isOpen" width="75vw">
                <add-customer :is-component="true" @added="selectCreatedCustomer"></add-customer>
            </mercur-dialog>
        </template>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import AddCustomer from '@/views/customers/Add'
import { mapActions, mapState } from 'vuex'
const debounce = require('lodash.debounce')

export default {
    name: 'CustomerSelector',
    components: { AddCustomer },
    props: {
        isWithTemporaryShop: {
            default: false,
        },
        isUsingVuex: {
            default: true,
        },
        withCreate: {
            default: false,
        },
    },
    data () {
        return {
            customerEmail: '',
            customerUrl: CONFIG.API.ROUTES.CUSTOMERS.OVERVIEW.replace('{agentId}', this.$store.state.auth.user.accountId),
            customers: [],
            selectedCustomer: null,
            isOpen: false,
        }
    },
    computed: {
        ...mapState('shop', ['temporaryShop']),
    },
    methods: {
        ...mapActions('customer', ['fetchCustomer']),
        searchCustomers: debounce(function (e) {
            const value = e.target.value.toLowerCase()
            if (value.length < 3) {
                return
            }

            const payload = {
                'request': {
                    'startRow': 0,
                    'endRow': 10,
                    'rowGroupCols': [],
                    'valueCols': [],
                    'pivotCols': [],
                    'pivotMode': false,
                    'groupKeys': [],
                    'filterModel': {
                        'email': {
                            type: 'contains',
                            filter: value,
                            filterType: 'text',
                        },
                    },
                    'sortModel': [],
                },
            }

            this.post(this.customerUrl, payload).then((data) => {
                this.customers = data.data
            })
        }, 500),
        selectCustomer (customer) {
            this.selectedCustomer = customer.accountId
            if (this.isUsingVuex) {
                this.fetchCustomer({
                    accountId: customer.accountId,
                }).then((data) => {
                    this.$emit('selected', data.data)
                })
            } else {
                const url = CONFIG.API.ROUTES.CUSTOMERS.GET.OVERVIEW.replace('{customerId}', customer.accountId)
                this.get(url).then((data) => {
                    this.$emit('selected', data.data)
                })
            }
        },
        isSelectedCustomer (customer) {
            return customer.accountId === this.selectedCustomer
        },
        selectCreatedCustomer (data) {
            const eventResponse = data.find(e => e.event === 'AccountWasCreated')
            if (!eventResponse) {
                this.$root.$emit('notification:global', {
                    message: 'There is no data for created customer',
                    type: 'error',
                })
                return
            }

            const accountData = eventResponse.response

            this.selectedCustomer = accountData.accountId
            this.$emit('selected', accountData)
            this.customers.push(accountData)
            this.isOpen = false
        },
        listen () {
            this.$set(this, 'customers', [])
            this.$set(this, 'selectedCustomer', null)
        },
    },
    mounted () {
        this.$bus.$on('shopWasChangedOnUniqueView', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChangedOnUniqueView', this.listen)
    },
}
</script>

<style lang="scss" scoped>
    .c-customer-box {
        &__selected-customer {
            background: #f2f2f2;
        }

        &__email {
            opacity: .7;
            font-size: 12px;
            word-break: break-all;
        }

        &__button {
            height: 32px;
            font-size: 13px;
            text-transform: uppercase;
        }
    }
</style>
