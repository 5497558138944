<template>
    <div class="c-address">
        <ul v-if="Object.keys(address).length" data-e2e="customerAddress" class="c-address__list">
            <li>{{ address.firstName }} {{ address.lastName }}</li>
            <li>{{ address.address }} {{ address.addressAdditional }}</li>
            <li>{{ address.zipCode }} {{ address.city }}</li>
            <li>{{ address.country }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'OrderAddress',
    props: {
        address: {
            default: function () {
                return {}
            },
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-address {
        padding: 0 5px;
        &__list {
            list-style: none;
            font-size: 13px;
            line-height: 18px;
            margin: 0;
            padding: 0;
        }
    }
</style>
