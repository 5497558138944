<template>
    <div class="c-add-order pb-5">
        <v-title title="Quotes - Add" />

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1 class="c-add-order__title">Add new quote</h1>
                    <mercur-select data-e2e="shopCountrySelector" v-model="orderCountry" class="c-add-order__currency">
                        <template #label>Quote currency and shop country</template>
                        <option v-for="(value, key) in workingCountriesList" :key="key" :value="key">{{ value.currency }} ({{ key }})</option>
                    </mercur-select>
                </div>
            </div>
        </title-box>

        <div class="container-fluid" v-if="selectedShop.applicationId !== '0' && orderCountry">
            <customer-selector :with-create="true" class="mt-n4 mb-4" @selected="setCustomer" />
            <template v-if="customer">
                <div class="row">
                    <div class="col-6">
                        <div class="d-flex align-items-end mb-2" data-e2e="billingAddress">
                            <div class="flex-grow-1">
                                <h3 class="font-weight-normal">Billing address</h3>
                                <template v-if="cart && cart.billingInfo">
                                    <order-address :address="cart.billingInfo.address" />
                                </template>
                            </div>
                            <div>
                                <mercur-button v-if="hasPermission('MerchantCentral/addAddress')" class="btn btn-primary text-uppercase" @click.native="triggerAddAddress">add new address</mercur-button>
                            </div>
                        </div>
                        <div>
                            <customer-addresses class="c-add-order__table mb-4"
                                                :customOptions="customOptions"
                                                :organisationId="customer.organisation.organisationId"
                                                ref="addresses"
                                                :customerId="customer.accountId"
                                                :canSetDefault="true"
                                                :isNotEditable="true"
                                                @setBilling="setBillingAddress"
                                                @setShipping="setShippingAddressForItems"
                                                v-if="hasPermission('listAddresses')"
                            >
                            </customer-addresses>
                        </div>
                        <mercur-card class="mb-4">
                            <funnel @productAdded="addItemToCart" :isCustomizable="true" :country="orderCountry" :is-free-fill-attributes="true" ref="funnel" />
                        </mercur-card>
                        <div>
                            <mercur-card class="mb-4">
                                <h3>Quote Attachments</h3>
                                <quote-uploader @uploaded="attachToCart" @remove="removeAttachmentFromCart" :customer="customer" />
                            </mercur-card>
                        </div>
                        <div class="text-right">
                            <mercur-button class="btn" to="/quotes">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" data-e2e="addQuoteButton" type="submit" v-if="hasPermission('MerchantCentral/addItemToCart')" :disabled="loading" @click="addQuote">Add Quote</mercur-button>
                        </div>
                    </div>
                    <div class="col-6">
                        <mercur-card class="mb-4">
                            <mercur-textarea v-model="cart.additionalCartInfo.customerRequirements">
                                Customer Requirements
                            </mercur-textarea>
                        </mercur-card>
                        <template v-if="cart.cartItems.length">
                            <mercur-card class="mb-4">
                                <h3 class="font-weight-normal">Products</h3>
                                <div v-for="(item, index) in cart.cartItems" :key="index">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <p><strong>{{ item.product.productName }}</strong></p>
                                            <table class="c-add-order__orderline-attributes">
                                                <tr v-for="(value, key) in item.product.attributes" :key="key">
                                                    <th class="c-add-order__orderline-attributes-key">{{ value.attributeName | capitalize }}</th>
                                                    <td>{{ value.attributeOption | capitalize }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-sm-6">
                                            <div>
                                                <p class="c-add-order__delivery-title">Delivery address</p>
                                                <div v-if="item.deliveryInfo" >
                                                    <order-address :address="item.deliveryInfo.address" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        <strong>Price: <currency :value="item.productPrice" :type="item.product.prices.currencyCode" /></strong>(vat: <currency :value="(item.productVatRate * item.productPrice).toFixed(2)" :type="item.product.prices.currencyCode" />)
                                        <span class="c-add-order__actions">
                                        <span>
                                            <mercur-tooltip>
                                                <template #label>Edit quote item</template>
                                                <mercur-button @click.native.prevent="editCartItem(item, index)" class="btn btn-icon"><i class="fas fa-edit"></i></mercur-button>
                                            </mercur-tooltip>
                                        </span>
                                        <span>
                                            <mercur-tooltip>
                                                <template #label>Duplicate quote item</template>
                                                <mercur-button class="btn btn-icon" v-if="hasPermission('MerchantCentral/addItemToCart')" @click.native.prevent="duplicateCartItem(item)">
                                                    <i class="far fa-clone"></i>
                                                </mercur-button>
                                            </mercur-tooltip>
                                        </span>
                                        <span>
                                            <mercur-tooltip>
                                                <template #label>Delete quote item</template>
                                                <mercur-button class="btn btn-icon" @click.native.prevent="removeItemFromCart(index)" v-if="hasPermission('MerchantCentral/removeItemFromCart')" :disabled="loading"><i class="fas fa-trash"></i></mercur-button>
                                            </mercur-tooltip>
                                        </span>
                                    </span>
                                    </p>
                                    <hr>
                                </div>
                                <h4 v-if="total">Total: <currency :value="total" :type="cart.cartItems[0].product.prices.currencyCode"></currency></h4>
                            </mercur-card>
                        </template>
                    </div>
                </div>
            </template>
            <div>
                <mercur-dialog :is-open.sync="isDialogOpen" width="600px">
                    <h3 class="font-weight-normal">Select quote items to change delivery address</h3>
                    <table data-e2e="orderlineTableForAddQuote" class="table">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Quote Item ID</th>
                                <th>Product</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cartItem in cart.cartItems" :key="cartItem.cartItemId">
                                <td><mercur-checkbox data-e2e="orderlineTableCheckboxForAddQuote" v-model="selectedItems" :value="cartItem"></mercur-checkbox></td>
                                <td>{{ cartItem.cartItemId }}</td>
                                <td>{{ cartItem.product.productName }}</td>
                                <td>{{ cartItem.productPrice.toFixed(2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <alert type="danger" v-if="hasCountryCurrencyError">Country of the selected address does not match selected currency for the quote!</alert>
                    <template #footer>
                        <mercur-button class="btn" @click.native.prevent="isDialogOpen = false" :disabled="loading">Close</mercur-button>
                        <mercur-button data-e2e="orderlineSaveForAddQuote" class="btn btn-primary" @click.native.prevent="setAddresses" :disabled="loading">Save</mercur-button>
                    </template>
                </mercur-dialog>
            </div>
            <edit-quote-dialog :fields="draft.cartItem" ref="editDialog" @change="updateCartItem" @closed="unsetItem">Edit quote item</edit-quote-dialog>
        </div>
        <div class="container-fluid" v-else>
            <mercur-card class="mt-n4">
                <p>Select a shop and currency to add  quote</p>
            </mercur-card>
        </div>
        <mercur-dialog :is-open.sync="isAddressAddDialogOpen">
            <address-form ref="addressForm" :options="addressFormOptions"  @success="onAddedAddress" />
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import Funnel from '@/components/elements/Funnel'
import CustomerAddresses from '@/components/elements/customers/Addresses'
import OrderAddress from '@/components/elements/order/Address'
import CustomerSelector from '@/components/elements/order/CustomerSelector'
import EditQuoteDialog from '@/components/elements/quotes/EditDialog'
import QuoteUploader from '@/components/elements/quotes/Uploader'
import Alert from '../../components/elements/Alert'
import { mapActions, mapGetters, mapState } from 'vuex'
import countries from 'country-region-data'
import AddressForm from '../../components/elements/AddressForm'

export default {
    name: 'AddQuote',
    components: {
        AddressForm,
        Funnel,
        CustomerAddresses,
        OrderAddress,
        CustomerSelector,
        QuoteUploader,
        EditQuoteDialog,
        Alert,
    },
    data () {
        return {
            isAddressAddDialogOpen: false,
            addressFormOptions: null,
            countries,
            cart: {
                cartId: this.$uuid.v4(),
                billingInfo: {},
                cartItems: [],
                additionalCartInfo: {},
            },
            isCreatingQuote: false,
            isDialogOpen: false,
            customer: null,
            activeAddress: null,
            selectedItems: [],
            loading: false,
            draft: {},
            customOptions: {
                paginationType: 'native',
            },
            orderCountry: null,
            hasCountryCurrencyError: false,
        }
    },
    computed: {
        ...mapGetters('shop', ['workingCountriesList']),
        ...mapState('shop', ['applicationVatProfiles']),
        total () {
            let value = 0
            this.cart.cartItems.forEach((item) => {
                const productPrice = parseFloat(item.productPrice)

                if (item.productVatRate) {
                    let vatValue = productPrice * item.productVatRate
                    value += productPrice + vatValue
                } else {
                    value += productPrice
                }
            })

            return value.toFixed(2)
        },
    },
    watch: {
        workingCountriesList: {
            handler (value) {
                if (Object.keys(value).length === 1) {
                    this.$set(this, 'orderCountry', Object.keys(value)[0])
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions('shop', ['fetchApplicationVatProfiles']),
        onAddedAddress () {
            this.isAddressAddDialogOpen = false
            this.$refs.addresses.$refs.table.refreshGrid()
        },
        triggerAddAddress () {
            this.addressFormOptions = {
                action: CONFIG.API.ROUTES.CUSTOMERS.ADDRESS.ADD
                    .replace('{customerId}', this.customer.accountId)
                    .replace('{organisationId}', this.customer.organisation.organisationId),
                title: 'Add new address',
                notification: 'Address added',
            }
            this.isAddressAddDialogOpen = true
        },
        async addQuote () {
            let isValid = true
            if (!this.cart.billingInfo.address) {
                this.$root.$emit('notification:global', {
                    message: 'Quote is not complete - set billing address',
                })
                return
            }
            if (!this.cart.cartItems.length) {
                this.$root.$emit('notification:global', {
                    message: 'Quote is not complete - there are no quote items',
                })
                return
            }
            this.cart.cartItems.forEach((item) => {
                if (!item.deliveryInfo.address) {
                    this.$root.$emit('notification:global', {
                        message: 'Quote is not complete - set shipping address for quote items',
                    })
                    isValid = false
                }
            })

            if (!isValid) {
                return
            }

            const url = CONFIG.API.ROUTES.CART.CREATE

            this.loading = true
            const payload = { ...this.cart, customerId: this.customer.accountId, email: this.customer.email, currency: this.$store.getters['shop/calculatedCurrency'](this.orderCountry) }

            if (this.customer.countryCode) {
                payload.countryCode = this.customer.countryCode
            }

            if (this.customer.language) {
                payload.language = this.customer.language
            }

            await this.post(url, payload, 'Quote was added').then(() => {
                setTimeout(() => {
                    this.$router.push({
                        name: 'QuotesWrapper',
                    })
                }, 500)
            }).finally(() => {
                this.loading = false
            })
        },
        async addItemToCart (product, productPrice = null) {
            let item = {
                product: product,
                productPrice: product.prices.currencySpecificPrices[this.$store.getters['shop/calculatedCurrency'](this.orderCountry)].salesPrice,
                shippingPrice: 0,
                productVatRate: this.selectedShop.configuration.vat ? this.selectedShop.configuration.vat.products : '0.23',
                shippingVatRate: this.selectedShop.configuration.vat ? this.selectedShop.configuration.vat.shipping : '0.23',
            }

            if (productPrice) {
                item.productPrice = productPrice
            }

            if (product.deliveryInfo) {
                item.deliveryInfo = {
                    deliveryDate: product.deliveryInfo.targetDeliveryDate,
                    targetDeliveryDate: product.deliveryInfo.targetDeliveryDate,
                    dispatchDate: product.deliveryInfo.targetDispatchDate,
                    targetDispatchDate: product.deliveryInfo.targetDispatchDate,
                }
            }

            if (item.product.deliveryInfo) {
                delete item.product.deliveryInfo
            }

            this.add(item)
        },
        async add (item) {
            item.cartItemId = this.$uuid.v4()
            this.cart.cartItems.push(item)
            this.$refs.funnel.clearFunnel()
        },
        duplicateCartItem (item) {
            const payload = JSON.parse(JSON.stringify(item))
            payload.cartItemId = this.$uuid.v4()
            this.cart.cartItems.push(payload)
        },
        async setCartBillingInfo (address) {
            if (!this.cart.billingInfo) {
                this.$set(this.cart, 'billingInfo', {})
            }
            this.$set(this.cart.billingInfo, 'address', address)
        },
        async removeItemFromCart (index) {
            this.cart.cartItems.splice(index, 1)
        },
        setBillingAddress (address) {
            this.setCartBillingInfo(address)
        },
        setShippingAddressForItems (address) {
            this.isDialogOpen = true
            this.activeAddress = address
            this.hasCountryCurrencyError = false
        },
        checkIfMatchesOrderCurrency () {
            const addressCountryCode = this.countries.find((e) => e.countryName === this.activeAddress.country).countryShortCode
            return this.workingCountriesList[addressCountryCode].currency === this.workingCountriesList[this.orderCountry].currency
        },
        setAddresses () {
            if (!this.checkIfMatchesOrderCurrency()) {
                this.hasCountryCurrencyError = true
                return
            }
            const ids = this.selectedItems.map((item) => item.cartItemId)
            this.cart.cartItems.filter((cartItem) => ids.includes(cartItem.cartItemId)).map((item) => {
                item.deliveryInfo.address = this.activeAddress
            })

            if (!this.applicationVatProfiles) {
                this.$root.$emit('notification:global', {
                    message: `Vat profiles for this shop are missing. We are trying to get them for you.`,
                    type: 'error',
                })
                this.fetchApplicationVatProfiles()
                return false
            }
            this.cart.cartItems.forEach(item => {
                const address = Object.assign(item.deliveryInfo.address, {})
                address.countryCode = this.countries.find((e) => e.countryName === address.country).countryShortCode
                if (!Object.keys(this.applicationVatProfiles).includes(address.countryCode)) {
                    this.$root.$emit('notification:global', {
                        message: `Shop does not ship to ${address.country}`,
                        type: 'error',
                    })
                    return false
                } else {
                    item.vatProfile = this.applicationVatProfiles[address.countryCode]
                }
            })

            this.isDialogOpen = false
        },
        onSelect (items) {
            this.selectedItems = items
        },
        setCustomer (customer) {
            this.customer = customer
        },
        updateCartItem (payload) {
            this.$set(this.cart.cartItems, [this.draft.index], payload)
            this.$refs.editDialog.resetForm()
        },
        unsetItem () {
            this.$set(this, 'draft', {})
        },
        editCartItem (item, index) {
            this.$set(this, 'draft', {
                cartItem: {
                    ...item,
                    currency: item.product.prices.currencyCode,
                },
                index: index,
            })
        },
        resetCart () {
            this.$set(this, 'cart', {
                cartId: this.$uuid.v4(),
                billingInfo: {},
                additionalCartInfo: {},
                cartItems: [],
            })
        },
        attachToCart (payload) {
            if (!this.cart.attachments) {
                this.cart.attachments = []
            }
            this.cart.attachments.push(payload)
        },
        removeAttachmentFromCart (name) {
            const index = this.cart.attachments.findIndex((e) => e.originalFileName === name)
            this.$delete(this.cart.attachments, [index])
        },
        listen () {
            this.$set(this, 'customer', null)
            this.unsetItem()
            this.resetCart()
        },
    },
    mounted () {
        this.$bus.$on('shopWasChangedOnUniqueView', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChangedOnUniqueView', this.listen)
    },
}
</script>
